h1 {
  font-weight: 500;
  color: #000000;
  font-size: 1.125rem;
  font-family: "Open Sans Pro";

  @media (max-width: 500px) {
    font-size: 0.938rem;
  }
}

h2 {
  font-weight: 500;
  color: #000000;
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
  font-size: 1.25rem;
  font-family: "Open Sans Pro";

  @media (max-width: 500px) {
    font-size: 1.125rem;
  }
}

h3 {
  font-weight: normal;
  color: #000000;
  font-size: 1.25rem;
  font-family: "Open Sans Pro";

  @media (max-width: 500px) {
    font-size: 1.125rem;
  }
}

h4 {
  font-weight: normal;
  color: #000000;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  font-size: 1.25rem;
  font-family: "Open Sans Pro";

  @media (max-width: 500px) {
    font-size: 1.125rem;
  }
}

h5 {
  font-weight: normal;
  color: #000000;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  font-size: 1.25rem;
  font-family: "Open Sans Pro";

  @media (max-width: 500px) {
    font-size: 1.125rem;
  }
}

h6 {
  font-style: italic;
  font-weight: bold;
  font-size: 1.25rem;
  font-family: "Open Sans Pro";

  @media (max-width: 500px) {
    font-size: 1.125rem;
  }
}

p {
  font-size: 1.25rem;
  font-family: "Open Sans Pro";

  @media (max-width: 500px) {
    font-size: 1.125rem;
  }
}

a {
  text-decoration: none;
  color: #000000;
  font-family: "Open Sans Pro";
}

a:hover {
  text-decoration: none;
  color: #000000;
}

a:visited {
  text-decoration: none;
}

sup {
  font-size: 15px;
}

.linkStyle {
  font-style: italic;
  font-weight: bold;
  font-size: 1.25rem;
  margin-left: 30px;

  @media (max-width: 500px) {
    font-size: 1.125rem;
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Open Sans", "Open Sans Light";
  scroll-behavior: smooth;
}